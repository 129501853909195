import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIApprovazione {


    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }

    getUrlDomainApi(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + "api/" + nome_metodo;


    }

    async getAziende(user) {


        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomain("EasyERGetAziendeApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async setAzienda(user, id, id_azienda) {


        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
        formData.append("id_azienda", id_azienda);

        return axios.post(this.getUrlDomain("EasyERSpeseSetAziendaApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getCDC(user, id_azienda, id_sel) {


        let formData = new FormData();

        formData.append("username", user);
        formData.append("id_azienda", id_azienda);
        formData.append("id_sel", id_sel);

        return axios.post(this.getUrlDomain("EasyERGetCDCApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async changeCDC(user, id, id_cdc) {


        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
        formData.append("id_cdc", id_cdc);

        return axios.post(this.getUrlDomain("EasyERSetCDCApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async changeStatus(user, id, id_status) {


        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
        formData.append("id_status", id_status);

        return axios.post(this.getUrlDomain("EasyERChangeStatusApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async downAttach(user, id) {


        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        return axios.post(this.getUrlDomain("EasyERDownAttachApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async downZipAttach(user, id) {


        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        return axios.post(this.getUrlDomain("EasyERDownZipApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }






}

export default new APIApprovazione({
    url: "http://emtool.local/public/api/smartbook/"
})
