<template>
    <div class="w-container-spesa editSpesa" style="padding-top: 84px;background-color: rgb(255 255 255 / 50%);">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
                background-color: white!important;
                color: black;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;
                min-width:100vw;
                border-color:#00246a;">{{ titolo }}</div>

            </v-col>

        </v-row>


        <div style="max-width:100%;" lazy-validation>






            <v-form id="mainForm" v-model="valid" ref="form" lazy-validation>



                <v-row>

                    <v-col cols="12" md="12" style="text-align: center">



                        <button type="button" id="upload-button" class="buttonFileEditSpesa" value="uplFile"
                            @click="openPopupUpload()">

                            <div class="containButtonFileEditSpesa">

                                <div class="button--text">{{ testoPulsante }}</div>

                                <div style="margin-left:10px;">
                                    <v-img src="@/assets/upload_file.png" max-width="20" max-height="20" />
                                </div>


                            </div>





                        </button>



                    </v-col>

                </v-row>






                <v-row>

                    <v-col cols="12" md="12">

                        <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                            offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field id="fldInpData" v-model="spese.date" label="Data" persistent-hint readonly
                                    v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="spese.date" no-title @input="showPicker = false"
                                locale="it"></v-date-picker>
                        </v-menu>


                    </v-col>


                </v-row>

                <v-row>

                    <v-col cols="12" md="12">

                        <v-text-field id="fldInpDescription" v-model="spese.description" label="Descrizione">
                        </v-text-field>

                    </v-col>

                </v-row>

                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field id="fldPiva" v-model="spese.piva" label="Partiva Iva">
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>

                    <v-col cols="12" md="12">

                        <div id="cont_idprojects_cdc_type" class="cl-div-ddl">
                            <ion-item id="itemTipo" class="cl-ddl-item">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Tipo
                                </ion-label>

                                <ion-select id="idprojects_cdc_type" name="idprojects_cdc_type"
                                    class='idprojects_cdc_type' cancel-Text="Chiudi" done-Text=""
                                    :value="spese.idprojects_cdc_type" interface="action-sheet" disabled readonly>

                                    <ion-select-option v-for="item in allTypes" :key="item.id" :value='item.id'>
                                        {{ item.title }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>


                    </v-col>


                </v-row>




                <v-row v-if="showKm">

                    <v-col cols="12" md="12">

                        <div id="d_km"
                            class="cl-importo v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Km</label>

                                        <vue-autonumeric v-model="spese.km" :disabled="true" id="fldInpAmount" :options="{
                    digitGroupSeparator: '.',
                    decimalCharacter: ',',
                    decimalCharacterAlternative: '.',
                    currencySymbol: '',
                    currencySymbolPlacement: 's',
                    roundingMethod: 'U',
                    minimumValue: '0'
                }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </v-col>

                </v-row>


                <v-row v-if="showKm">
                    <v-col cols="12" md="12">
                        <v-textarea id="fldNote" v-model="spese.note" label="Note">
                        </v-textarea>
                    </v-col>
                </v-row>






                <v-row>

                    <v-col cols="12" md="12">

                        <div id="d_importo2"
                            class="cl-importo v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Importo</label>

                                        <vue-autonumeric v-model="spese.amount" :disabled="true" id="fldInpAmount"
                                            :options="{
                    digitGroupSeparator: '.',
                    decimalCharacter: ',',
                    decimalCharacterAlternative: '.',
                    currencySymbol: '\u00a0€',
                    currencySymbolPlacement: 's',
                    roundingMethod: 'U',
                    minimumValue: '0'
                }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </v-col>

                </v-row>




            </v-form>






        </div>



        <div class="contpopupimporto">

            <template>
                <modal name="popupImporto" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Importo</label>

                            <div id="d_importo_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreImporto" :options="{
                    digitGroupSeparator: '.',
                    decimalCharacter: ',',
                    decimalCharacterAlternative: '.',
                    currencySymbol: '\u00a0€',
                    currencySymbolPlacement: 's',
                    roundingMethod: 'U',
                    minimumValue: '0'
                }" ref="fldImporto" id="fldImporto"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-importo" contain src="@/assets/btn_cancel3_easyer.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-importo" contain src="@/assets/btn_confirm3_easyer.png"
                                    max-width="30" max-height="30" style="cursor:pointer"
                                    transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="conttipi">

            <template>
                <div class="text-center">

                    <v-bottom-sheet v-model="sheetTypes">
                        <v-sheet class="text-center" height="530px" style="padding-bottom:100px;">

                            <div style="text-align:right!important">
                                <v-btn class='closeTypesSheet' icon @click="sheetTypes = !sheetTypes">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                            </div>


                            <div class="py-3 c_title_tipi">
                                Tipi
                            </div>

                            <v-list-item v-for="tile in allTypes" :key="tile.id" @click="setType(tile.id)">
                                <v-list-item-avatar>

                                    <v-avatar :size="tile.sizeAvatar" tile>

                                        <v-img alt="" contain :src="tile.img" :max-width="tile.width"
                                            :max-height="tile.height" />

                                    </v-avatar>

                                </v-list-item-avatar>
                                <v-list-item-title style="text-align:left!important;font-size:9px!important">{{
                    tile.title
                }}</v-list-item-title>
                            </v-list-item>

                        </v-sheet>
                    </v-bottom-sheet>

                </div>
            </template>

        </div>


        <div class="contpopupkm">

            <template>
                <modal name="popupKm" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Km</label>

                            <div id="d_importo_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreKm" :options="{
                    digitGroupSeparator: '.',
                    decimalCharacter: ',',
                    decimalCharacterAlternative: '.',
                    currencySymbol: '',
                    currencySymbolPlacement: 's',
                    roundingMethod: 'U',
                    minimumValue: '0'
                }" ref="fldImporto" id="fldImporto"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-km" contain src="@/assets/btn_cancel3_easyer.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-km" contain src="@/assets/btn_confirm3_easyer.png"
                                    max-width="30" max-height="30" style="cursor:pointer"
                                    transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupuploadfile">

            <template>
                <modal name="popupUploadFile" :clickToClose="false" :width="386" :height="341">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:11px;margin-left:8px;font-weight: bold;">{{ titoloPopup2 }}</label>


                        </v-col>

                    </v-row>

                    <v-row class="rowPrevFile">

                        <v-col cols="12" md="12" style="margin-left: 12px;">


                            <span class="files">
                                <input type="file" id="input-file-attach" name="logo" :accept="fileAccettati"
                                    class="dropify" data-max-width="6610" data-max-height="6200" />
                            </span>


                        </v-col>

                    </v-row>


                    <v-row style="margin-top: 0px!important;">

                        <v-col cols="12" md="12" style="margin-top: -23px!important;display:flex">

                            <label style="font-size:11px;margin-left:12px;font-weight: bold;">File:</label>

                            <div style="margin-left: 10px;font-size:11px;font-weight: bold;">{{ nomeFilePresente }}
                            </div>

                        </v-col>

                    </v-row>

                    <v-row style="margin-top: 0px!important;">

                        <v-col cols="12" md="12" style="margin-top: -7px!important;display:flex">

                            <label style="font-size:11px;margin-left:12px;font-weight: bold;">Dim:</label>

                            <div style="margin-left: 10px;font-size:11px;font-weight: bold;">{{ dimFilePresente }}</div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-upload" contain src="@/assets/btn_cancel3_easyer.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelUpload" />

                                <v-img alt="" class="btn-confirm-upload" contain src="@/assets/btn_confirm3_easyer.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmUpload" />


                            </div>

                        </v-col>

                    </v-row>



                </modal>
            </template>

        </div>






    </div>
</template>

<script>

/* eslint-disable */
import { createWorker, PSM, OEM } from 'tesseract.js';
const worker = createWorker({
    logger: m => console.log(m),
});

import apiusers from "../utils/users/apiusers";
import apispese from "../utils/spese/apispese";

import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import apiuploadfile from "../utils/uploadfile/apiuploadfile";

import {
    bus
} from "../main";

import router from ".././router";



/* import Camera from "./qrcode.vue" */


export default ({
    mounted() {


        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        var pointerVue = this;



        // di default data corrente
        // const date = new Date();

        // let day = date.getDate();
        // let month = date.getMonth() + 1;
        // let year = date.getFullYear();

        // let currentDate = `${year}-${month}-${day}`;

        var currentDate = pointerVue.getCurrentDate();

        console.log("CUR DATE: ", currentDate);


        pointerVue.spese.date = currentDate;



        if (this.$route.params.id == 0) {

            setTimeout(() => {


                this.initInsert();

            }, 100);


        }






        this.setupButtons();


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save_preview_image":
                    this.saveImage();
                    break;

                case "btn_back":
                    router.push({
                        path: "/spese"
                    });
                    break;



                case "btn_reco":
                    var hasImage = false;
                    try {

                        hasImage = $(".dropify-render img").attr("src").length;

                    } catch (error) {

                    }
                    if (hasImage) {


                        // this.recognizeWs();
                        this.recognize();

                    }
                    else {
                        this.$swal({
                            icon: "error",
                            text: "Per favore inserire un'immagine",
                            showConfirmButton: false,
                            timer: 2000
                        });

                    }


                    break;



                case "btn_save":

                    this.validate();

                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {



            $('body').on('ionChange', '#idprojects_cdc_type', function (event) {
                console.log(event);

                pointerVue.spese.idprojects_cdc_type = this.value;
                pointerVue.spese.km = 0;

                pointerVue.showKm = false;

                if (this.value == 20) {


                    pointerVue.showKm = true;


                }



            });








            $('body').on('click', '#cont_idprojects_cdc_type', function (event) {
                console.log(event);

                pointerVue.sheetTypes = true;

            });



            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                //console.log(oggDrop);

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;
                    pointerVue.imageB64 = "";

                    pointerVue.spese.date = "";
                    pointerVue.spese.piva = "";

                    $("#fldPiva").val("");
                    $("#fldInpData").val("");
                    $("#fldInpAmount").val("");


                    console.log("pointerVue.spese", pointerVue.spese);

                    pointerVue.setupButtons();

                    document.getElementById("imgPreview").src = "";

                });

            }, 100);

            $('#input-file-logo').on('change', async function () {

                pointerVue.attachRemove = false;

                const imageFile = document.querySelector('#input-file-logo').files[0];

                //Resize start
                var reader = new FileReader();
                reader.onload = function (e) {
                    var img = document.createElement("img");
                    img.onload = function (event) {
                        // Dynamically create a canvas element
                        var canvas = document.createElement("canvas");

                        // var canvas = document.getElementById("canvas");
                        var ctx = canvas.getContext("2d");
                        ctx.mozImageSmoothingEnabled = false;
                        ctx.webkitImageSmoothingEnabled = false;
                        ctx.msImageSmoothingEnabled = false;
                        ctx.imageSmoothingEnabled = false;


                        var width = 600;
                        var height = 600;


                        // Actual resizing
                        canvas.width = width;
                        canvas.height = height;
                        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height);


                        // Show resized image in preview element
                        var dataurl = canvas.toDataURL(imageFile.type);
                        document.getElementById("imgPreview").src = dataurl;
                    }
                    img.src = e.target.result;
                }

                setTimeout(() => {
                    pointerVue.setupButtons();
                }, 600);


                reader.readAsDataURL(imageFile);


                //Resize end

                /*           const result = await pointerVue.toBase64(imageFile);
          
                          console.log(result);
                          pointerVue.imageB64 = result;
          
                          document.getElementById("imgPreview").src = result; */


                //pointerVue.recognize();



            });




            $('body').on('click', '#d_importo2', function (event) {
                console.log(event);


                if (pointerVue.spese.idprojects_cdc_type != 20) {

                    pointerVue.$modal.show('popupImporto');


                }


            });


            $('body').on('click', '#d_km', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupKm');

            });


            $('body').on('click', '.btn-confirm-importo', function (event) {
                console.log(event);

                pointerVue.spese.amount = pointerVue.valoreImporto;

                pointerVue.$root.$children[0].setFieldActive("#d_importo2");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-importo', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });



            $('body').on('click', '.btn-confirm-km', function (event) {
                console.log(event);

                pointerVue.spese.km = pointerVue.valoreKm;

                pointerVue.$root.$children[0].setFieldActive("#d_km");

                pointerVue.$modal.hide('popupKm');


                pointerVue.calcImpEuroForKm();




            });

            $('body').on('click', '.btn-cancel-km', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupKm');

            });










        });

    },

    components: {
        VueAutonumeric,
        /*   Camera */
    },


    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({


        // parametri funzione upload
        testoPulsante: "Upload Foto",
        titoloPopup2: "Allega Foto",
        fileAccettati: ".png,.jpg,.jpeg",
        nomeFilePresente: "",
        dimFilePresente: "",
        dimKbFilePresente: 0,
        dimMinKbFile: 100000,
        dimMaxKbFile: 5000000,
        folderServerToSave: "/documents/easyer/",
        folderDoxToSave: "/Products/Easyer/",
        removeUploadFile: 0,
        attachRemoveVisura: false,
        attachPresent: false,






        valoreKm: null,

        showKm: false,
        sheetTypes: false,

        allTypes: [{
            img: 'https://app.emtool.eu/public/_lib/img/hotel_easyer.png',
            title: 'Hotel',
            "id": 5,
            icon: "mdi-account-lock-open",
            "color": "red",
            width: 32,
            height: 32,
            sizeAvatar: "32px"
        },

        {
            img: 'https://app.emtool.eu/public/_lib/img/carburante_easyer.png',
            title: 'Carburante',
            "id": 8,
            icon: "mdi-account-lock-open",
            "color": "red",
            width: 32,
            height: 32,
            sizeAvatar: "32px"
        },

        {
            img: 'https://app.emtool.eu/public/_lib/img/vitto_easyer.png',
            title: 'Vitto',
            "id": 10,
            icon: "mdi-account-lock-open",
            "color": "red",
            width: 32,
            height: 32,
            sizeAvatar: "32px"
        },

        {
            img: 'https://app.emtool.eu/public/_lib/img/aereo_easyer.png',
            title: 'Viaggio',
            "id": 15,
            icon: "mdi-account-lock-open",
            "color": "red",
            width: 32,
            height: 32,
            sizeAvatar: "32px"

        },

        {
            img: 'https://app.emtool.eu/public/_lib/img/spese/17.png',
            title: 'Noleggio',
            "id": 19,
            icon: "mdi-account-lock-open",
            "color": "red",
            width: 25,
            height: 25,
            sizeAvatar: "25px"

        },

        {
            img: 'https://app.emtool.eu/public/_lib/img/altro_easyer.png',
            title: 'Altro',
            "id": 2,
            icon: "mdi-account-lock-open",
            "color": "red",
            width: 32,
            height: 32,
            sizeAvatar: "32px"

        },

        {
            img: 'https://app.emtool.eu/public/_lib/img/euro-km-easyer.png',
            title: '€ * KM',
            "id": 20,
            icon: "mdi-account-lock-open",
            "color": "red",
            width: 32,
            height: 32,
            sizeAvatar: "32px"

        },

        ],


        showPicker: false,

        imageB64: "",
        result: "",
        valoreImporto: null,

        showCamera: true,

        showForm: true,

        showPicker2: false,

        valoreMassimale: null,

        changePsw: false,

        titolo: "Aggiungi Spesa",
        currDipendenteObj: {},

        gruppi_checked: [],

        aziende: [],
        prefissi: [],

        spese: { idprojects_cdc_type: "", km: "", description: "" },

        dipendenti: [],
        currAzienda: "",

        gruppi: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        utente: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,



        items: [
            'Informazioni', 'Gruppo', 'Nota Spese'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ]

    }),

    methods: {


        uploadFile: async function () {



            var that = this;

            console.log("FATT FILE: ", that.fattura);

            var id_rec = that.$route.params.id;


            var v_file = $("#input-file-attach")[0].files[0];

            that.$modal.hide('popupUploadFile');


            that.attachPresent = true;


            console.log("ID_REC: ", id_rec);
            console.log("FILE: ", v_file);
            console.log("FOLDER SERVER: ", that.folderServerToSave);
            console.log("FOLDER DOX: ", that.folderDoxToSave);

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;



            var response3 = await apiuploadfile.setFileEasyer(
                id_rec,
                v_file,
                that.folderServerToSave,
                that.folderDoxToSave,
                "foto_easyer",
                v_token,
                that.removeUploadFile
            ).then((res2) => {

                console.log(response3);

                that.$root.$children[0].showProgress = false;
                console.log("res from setFileEasyer", res2);


                that.$swal({
                    icon: "success",
                    text: "File caricato correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });



            }).catch(err2 => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err2.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }


            );





        },



        btnCancelUpload: function () {

            this.$modal.hide('popupUploadFile');

        },


        btnConfirmUpload: function () {

            var errore = "";

            var v_file = $("#input-file-attach")[0];

            if (v_file.files.length > 0) {



                // controllo estensione

                v_file = v_file.files[0];

                console.log("FILE 1: ", v_file);

                var v_elements = v_file.name.split('.');

                var v_ext_file = v_elements[v_elements.length - 1];

                console.log("FILE ELEMENTS: ", v_elements);
                console.log("FILE EXT: ", v_ext_file);


                var v_ele_file_accept = this.fileAccettati.split(',');

                console.log("FILE ELEMENTS ACCEPT: ", v_ele_file_accept);

                var v_find_ext = 0;

                for (var xx = 0; xx < v_ele_file_accept.length; xx++) {

                    var v_ext_file_tmp = "." + v_ext_file;

                    console.log(v_ext_file_tmp);

                    if (v_ext_file_tmp == v_ele_file_accept[xx]) {
                        v_find_ext = 1;
                    }

                }

                if (v_find_ext == 0) {
                    errore = "I formati accettati sono " + this.fileAccettati;
                }



                if (errore == "") {

                    // controllo dimensione file

                    if ((this.dimKbFilePresente < this.dimMinKbFile) || (this.dimKbFilePresente > this.dimMaxKbFile)) {


                        var strMin = (this.dimMinKbFile / 1000) + " Kb";

                        var strMax = (this.dimMaxKbFile / 1000000) + " Mb";

                        errore = "La grandezza del file deve essere compresa tra " + strMin + " e " + strMax;

                    }


                }






            }




            if (errore == "") {

                console.log("ok");

                this.uploadFile();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3500
                });


            }


        },




        openPopupUpload: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.nomeFilePresente = "";
            that.dimFilePresente = "";
            that.dimKbFilePresente = 0;


            var id_rec = that.$route.params.id;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;



            var response3 = await apiuploadfile.getFile(
                id_rec,
                "foto_easyer",
                that.folderServerToSave,
                v_token
            ).then((res2) => {

                console.log(response3);

                that.$root.$children[0].showProgress = false;
                console.log("res from getFile", res2);


                that.dimMinKbFile = res2.data.MinFileSize;

                that.dimMaxKbFile = res2.data.MaxFileSize;


                $(".contpopupuploadfile").show();

                this.$modal.show('popupUploadFile');


                setTimeout(() => {


                    var oggDrop = $('.dropify').dropify({
                        messages: {
                            default: 'Drag',
                            replace: /* s */ '',
                            remove: 'Rimuovi',
                            error: 'File troppo grande'
                        }
                    });

                    $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                    $(".dropify-render").append("<img>");

                    oggDrop.on('dropify.afterClear', function (event, element) {

                        console.log(event, element);

                        that.removeUploadFile = 1;

                        that.nomeFilePresente = "";
                        that.dimFilePresente = "";
                        that.dimKbFilePresente = 0;

                    });



                    $('#input-file-attach').on('change', function () {

                        that.removeUploadFile = 0;

                        var v_file = $("#input-file-attach")[0].files[0];

                        console.log("FILE: ", v_file);

                        that.nomeFilePresente = v_file.name;

                        var v_size = v_file.size;

                        that.dimKbFilePresente = v_size;

                        var str_size = "0 Kb";


                        if (v_size > 0) {

                            var v_division = v_size / 1000;

                            str_size = v_division.toFixed(1) + " Kb";


                        }



                        that.dimFilePresente = str_size;



                    });



                    console.log("FILE PRESENT: ", res2.data.NomeFile);

                    if (res2.data.NomeFile != null) {

                        if (res2.data.NomeFile != "") {

                            var elements = res2.data.NomeFile.split(".");

                            console.log("ELEMENTS: ", elements);

                            var v_ext = elements[elements.length - 1];

                            console.log("EXT: ", v_ext);

                            if (v_ext == "pdf") {

                                $(".rowPrevFile").find('.dropify-render').append("<i class='dropify-font-file'></i>");
                                $(".rowPrevFile").find('.dropify-render').append("<span class='dropify-extension'>" + v_ext + "</span>");
                                $(".rowPrevFile").find(".dropify-wrapper").addClass("has-preview");
                                $(".rowPrevFile").find(".dropify-preview").show("slow");

                            }
                            else {


                                var v_url = this.getUrlDomain("public/" + this.folderServerToSave) + res2.data.NomeFile;



                                //     $(".dropify-render img").attr("src", txt_image);
                                // $(".dropify-wrapper").addClass("has-preview");
                                // $(".dropify-preview").show("slow");



                                $(".rowPrevFile").find('.dropify-render img').attr("src", v_url);
                                $(".rowPrevFile").find(".dropify-wrapper").addClass("has-preview");
                                $(".rowPrevFile").find(".dropify-preview").show("slow");


                            }




                            that.nomeFilePresente = res2.data.NomeFile;

                            that.dimKbFilePresente = res2.data.FileSize;


                            var str_size = "0 Kb";

                            var v_size = res2.data.FileSize;

                            if (v_size > 0) {

                                var v_division = v_size / 1000;

                                str_size = v_division.toFixed(1) + " Kb";


                            }



                            that.dimFilePresente = str_size;

                        }


                    }








                }, 300);



            }).catch(err2 => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err2.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }


            );

        },






        getCurrentDate: function () {

            const date = new Date();

            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            if (month.toString().length < 2)
                month = '0' + month;
            if (day.toString().length < 2)
                day = '0' + day;

            //console.log("DAY LEN: ", day.toString().length);

            // console.log("DAY: ", day);
            // console.log("MONTH: ", month);
            // console.log("YEAR: ", year);

            let currentDate = `${year}-${month}-${day}`;

            return currentDate;

        },

        setType: function (id_type) {

            this.spese.idprojects_cdc_type = id_type;

            this.sheetTypes = false;

        },


        toBase64: function (file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });

        },



        getTotal: function (text) {

            var candidate = 0.0;
            var stringArray = text.split(/(\s+)/);

            for (var i in stringArray) {
                var actual = stringArray[i].replace(",", ".");
                var part = parseFloat(actual);
                //    console.log ( "part",part ); 
                if (part > candidate && actual.includes(".")) candidate = part;
            }

            var formated = candidate.toFixed(2);
            console.log("ar", formated);
            this.spese.amount = formated;




            var piva = "";
            var pivaTest = /^[0-9]{11}$/


            candidate = "";
            for (var i in stringArray) {

                var actual = stringArray[i];

                if (pivaTest.test(actual)) {
                    piva = actual;
                    console.log("piva", piva);

                    this.spese.piva = piva;

                }


                if ((actual.includes("-") || actual.includes("/")) && (actual.length == 10 || actual.length == 8)) candidate = actual;
            }


            var itaDate = "";
            var sign = "-";
            var arr = [];

            if (candidate.includes("-")) sign = "-";
            if (candidate.includes("/")) sign = "/";

            if (candidate.length > 0) {
                if (candidate.includes(sign)) {
                    var arr = candidate.split(sign);

                    var year = arr[2];
                    if (year.length == 2) {
                        year = "20" + year;
                    }
                    itaDate = year + "-" + arr[1] + "-" + arr[0]

                }
            }


            if (arr.length != 3) itaDate = "";

            $("#fldInpData").val(itaDate)
            this.spese.date = itaDate;




        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        recognizeWs: async function () {


            var pointerVue = this;
            var data = $("#imgPreview").attr("src");
            var data = $("#imgPreview").attr("src");
            console.log(" Data : ", data);


            this.$root.$children[0].showProgress = true;
            $.ajax({
                method: "POST",
                url: this.getUrlDomain("EasyEROcrApi"),
                data: {
                    "data": data

                },
                success: function (data) {
                    var text = data.res;


                    var tot = pointerVue.getTotal(text);
                    var acapo = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
                    document.getElementById("preview").innerHTML = acapo;


                    pointerVue.$root.$children[0].showProgress = false;


                }
            });



        },


        recognize: async function () {

            this.$root.$children[0].showProgress = true;


            setTimeout(() => {

                $(".v-progress-circular__info").text("Searching Date");

            }, 100);

            const img = document.getElementById('imgPreview');

            console.log(img);
            await worker.load();
            await worker.loadLanguage('ita');
            await worker.initialize('ita', OEM.LSTM_ONLY);
            await worker.setParameters({
                tessedit_pageseg_mode: PSM.SINGLE_BLOCK,
            });

            var part = {
                rectangle: { top: 200, left: 0, width: 410, height: 200 },
            };



            setTimeout(() => {

                $(".v-progress-circular__info").text("Searching Total");

            }, 5000);

            const { data: { text } } = await worker.recognize(img);


            var tot = this.getTotal(text);
            var acapo = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
            document.getElementById("preview").innerHTML = acapo;
            console.log(text);
            this.$root.$children[0].showProgress = false;



        },







        saveImage: async function () {

            var isCamera = null;

            var that = this;

            try {
                isCamera = this.$refs.camera.getImage();

            } catch (error) {
                console.log(error);
            }

            if (isCamera != null) {
                var dataImage = await this.$refs.camera.getImage();

                this.showCamera = false;

                that.spese.amount = null;
                that.valoreImporto = null;

                $("#fldInpAmount").val("");

                var img = document.getElementById("imgPreview");
                img.src = dataImage;
                console.log(dataImage);


                this.recognize();



                this.setupButtons();

            }
            else {
                console.log("No camera");
            }




        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.utente.idcompany_logo = azienda.companyid;

        },


        setupButtons: async function () {

            setTimeout(() => {
                var hasImage = $(".dropify-render img").length;

                var pulsantis = [];

                /*   if (this.showCamera) {
  
                      pulsantis.push({
                          text: "Salva",
                          icon: "mdi-content-save",
                          link: "/timesheet",
                          id: "btn_save_preview_image",
                          disabled: false,
                          image: "https://app.emtool.eu/public/_lib/img/camera.png",
                          class: "inverted",
                          title: "Scatta Foto"
  
                      });
  
                  }
                  else { */


                pulsantis.push({
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/save_easyer2.png",
                    title: "Salva",
                    width: 30

                });

                // /*   if ( hasImage == 1 )
                //   { */
                // pulsantis.push
                //     (
                //         {
                //             text: "Salva",
                //             icon: "mdi-content-save",
                //             link: "/save",
                //             id: "btn_reco",
                //             disabled: false,
                //             image: "https://app.emtool.eu/public/_lib/img/scontrino_easyer2.png",
                //             //class: "inverted",
                //             title: "Riconosci",
                //             width: 22

                //         });
                // /*   } */


                // /*   } */


                pulsantis.push({
                    text: "Parenti",
                    icon: "mdi-arrow-left",
                    link: "/parenti",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    image: "https://app.emtool.eu/public/_lib/img/back-easyer.png",
                    width: 30


                });

                this.$root.$children[0].bnavbuttons = pulsantis;


            }, 100);

        },

        verifyAttachmentIsImage: function (content) {

            var elements = content.split(",");

            console.log("ELE: ", elements);

            var result = false;

            if (elements[0].indexOf("image") > 0) {
                result = true;
            }

            return result;

        },

        validate() {

            var that = this;

            var img = document.getElementById("imgPreview");

            console.log("SPESE: ", that.spese);

            console.log("DATA: ", $("#fldInpData").val());

            var errore = "";



            if (that.attachPresent == false) {
                errore = "Specificare un'allegato";
            }



            if (errore == "") {

                if ($("#fldInpData").val() == "") {

                    errore = "Specificare la data";

                }

            }


            if (errore == "") {

                if (that.spese.description == "") {

                    errore = "Specificare la descrizione";

                }

            }



            if (errore == "") {

                if (that.spese.idprojects_cdc_type == "") {

                    errore = "Specificare il tipo";

                }

            }

            if (errore == "") {

                if (that.spese.idprojects_cdc_type == 20) {

                    if (that.spese.km == "") {

                        errore = "Specificare i km";


                    }


                }

            }




            if (errore == "") {

                if ($("#fldInpAmount").val() == "") {

                    errore = "Specificare l'importo";

                }

            }



            if (errore == "") {

                console.log("ok");

                this.saveData();

            }
            else {


                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }







        },


        calcImpEuroForKm: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("SPESE: ", that.spese);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apispese.calcImpEuroForKm(
                v_token,
                that.spese.km
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from calcImpEuroForKm", res);


                that.spese.amount = res.data.Result;


            }).catch(err2 => {
                that.$root.$children[0].showProgress = false;
                console.log(err2);
                var msg = err2.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },


        initInsert: async function () {

            var that = this;

            console.log("SPESE: ", that.spese);

            // var img = document.getElementById("imgPreview");

            // console.log("FOTO: ", img.src);

            var v_token = window.$cookies.get("token");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response2 = await apispese.initInsert(
                v_token
            ).then((res2) => {

                console.log(response2);

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsert", res2);



            }).catch(err2 => {
                that.$root.$children[0].showProgress = false;
                console.log(err2);
                var msg = err2.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );








        },



        saveData: async function () {

            var that = this;

            console.log("SPESE: ", that.spese);



            var v_token = window.$cookies.get("token");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apispese.validateInsertSpese(
                v_token,
                that.spese.date,
                that.spese.amount
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from validateInsertSpese", res);

                if (res.data.Result == "OK") {

                    console.log("OK");


                    that.showSpinner = true;
                    that.$root.$children[0].showProgress = true;

                    var response2 = await apispese.insertSpese(
                        that.spese,
                        undefined,
                        v_token
                    ).then((res2) => {

                        that.$root.$children[0].showProgress = false;
                        console.log("res from insertSpese", res2);

                        var id_inserted = res2.data.IdInsert;

                        that.$swal

                            .fire({
                                title: "Approvazione",
                                html: "La spesa è stata inserita la si vuole inviare in approvazione ?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_easyer.png' />",
                                cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_easyer.png' />",
                                customClass: {
                                    cancelButton: 'order-1',
                                    confirmButton: 'order-2',
                                }
                            })
                            .then(async function (result) {

                                if (result.value == true) {


                                    that.showSpinner = true;
                                    that.$root.$children[0].showProgress = true;

                                    var response3 = await apispese.validateForAppr(
                                        v_token,
                                    ).then(async (res3) => {

                                        console.log("res from validateForAppr", res3);

                                        if (res3.data.Result == "OK") {


                                            var response4 = await apispese.sendForAppr(
                                                v_token,
                                                id_inserted
                                            ).then((res4) => {

                                                that.$root.$children[0].showProgress = false;
                                                console.log("res from sendForAppr", res4);

                                                that.$swal({
                                                    icon: "success",
                                                    text: "Dati inviati correttamente",
                                                    showConfirmButton: false,
                                                    timer: 2000
                                                });

                                                setTimeout(() => {


                                                    router.push({
                                                        path: "/spese"
                                                    });

                                                }, 200);

                                            }).catch(err4 => {
                                                that.$root.$children[0].showProgress = false;
                                                console.log(err4);
                                                var msg2 = err4.response.data.Error;

                                                that.$swal({
                                                    icon: "error",
                                                    text: msg2,
                                                    showConfirmButton: false,
                                                    timer: 8000
                                                });
                                                console.log("Errori", "Non è stato possibile eliminare i dati");
                                                console.log("response", response4);

                                            }

                                            );




                                        }
                                        else {

                                            that.$swal({
                                                icon: "error",
                                                text: res.data.ErrDetails,
                                                showConfirmButton: false,
                                                timer: 2000
                                            });



                                        }



                                    }).catch(err5 => {
                                        that.$root.$children[0].showProgress = false;
                                        console.log(err5);
                                        var msg = err5.response.data.Error;

                                        that.$swal({
                                            icon: "error",
                                            text: msg,
                                            showConfirmButton: false,
                                            timer: 8000
                                        });
                                        console.log("Errori", "Non è stato possibile eliminare i dati");
                                        console.log("response", response3);

                                    }

                                    );





                                }
                                else {

                                    setTimeout(() => {


                                        router.push({
                                            path: "/spese"
                                        });

                                    }, 200);


                                }

                            });




                        // that.$swal({
                        //     icon: "success",
                        //     text: "Dati salvati correttamente",
                        //     showConfirmButton: false,
                        //     timer: 2000
                        // });

                        // setTimeout(() => {


                        //     router.push({
                        //         path: "/spese"
                        //     });

                        // }, 200);

                    }).catch(err2 => {
                        that.$root.$children[0].showProgress = false;
                        console.log(err2);
                        var msg = err2.response.data.Error;

                        that.$swal({
                            icon: "error",
                            text: msg,
                            showConfirmButton: false,
                            timer: 8000
                        });
                        console.log("Errori", "Non è stato possibile salvare i dati");
                        console.log("response", response2);

                    }

                    );







                }

                if (res.data.Result == "ERROR") {

                    console.log("ERROR");

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });



                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );








        },

        syncUtente: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getUtente(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUtente", res);

                try {

                    this.aziende = res.data.aziende;
                    this.prefissi = res.data.prefix;
                    this.gruppi = res.data.models;
                    this.utente = res.data.curr_user;

                    this.utente.codice_fiscale = res.data.val_codice_fiscale;

                    var grp_selected = res.data.curr_user.models.split(",");

                    console.log("GRP SEL: ", grp_selected);

                    if (grp_selected.length > 0) {

                        for (var i = 0; i < this.gruppi.length; i++) {

                            if (this.gruppi[i] !== undefined) {

                                for (var x = 0; x < grp_selected.length; x++) {

                                    console.log("GRP SEL 2: ", grp_selected[x]);

                                    if (parseInt(this.gruppi[i].id) == parseInt(grp_selected[x])) {

                                        this.gruppi[i].checked = this.gruppi[i].id;

                                    }

                                }

                            }

                        }

                    }

                    $("#itemAzienda").addClass("item-has-value");

                    if (res.data.info_easyer != null) {

                        this.utente.enable_easyer = res.data.info_easyer.enable;

                        this.utente.max_easyer = res.data.info_easyer.max_value;

                        this.utente.datestart_easyer = res.data.info_easyer.period_from;

                        this.utente.dateend_easyer = res.data.info_easyer.period_to;


                    }






                    //console.log("ok");

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.createUtenteApi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from createUtenteApi", res);

                try {

                    this.aziende = res.data.aziende;

                    this.prefissi = res.data.prefix;

                    this.gruppi = res.data.models;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncPrefix: async function () {

            var that = this;

            //var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getPrefix().then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPrefix", res);

                try {

                    this.prefissi = res.data.tel_prefix;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.editSpesa .rowPrevFile .dropify-wrapper {
    height: 187px !important;
    width: 363px !important;
    margin-bottom: 20px;
    margin-left: 1px;
}



.buttonFileEditSpesa {
    border: 1px solid black;
    padding: 10px;
    width: 162px;
}

.containButtonFileEditSpesa {
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
}






.cl-div-ddl {
    cursor: pointer;
    margin-left: 9px !important;
}

.cl-ddl-item {
    min-width: 190px !important;
    margin-top: -8px;
    margin-bottom: 18px;
    max-width: 424px !important;
}


.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-spesa {
    width: 100%;
    padding: 0px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editSpesa .v-form {
    padding-top: 114px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    overflow-y: auto !important;
    max-height: 100vh !important;
    padding-bottom: 300px !important;
    overflow-x: hidden !important;
    margin-top: 10px !important;
}

.editSpesa .v-input {
    font-size: 1.2em !important;
}

.editSpesa .v-select {
    font-size: 1.2em !important;
}

.editSpesa .v-label {
    font-size: 1em !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}

.cl-importo {
    max-width: 434px;
    padding-left: 0px;
    margin-left: 11px;
}


@media screen and (max-width: 768px) {

    .editSpesa .v-form {
        padding-top: 45px !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }


    .cl-div-ddl {
        cursor: pointer;
        margin-left: -11px !important;
    }

    .cl-ddl-item {
        min-width: 190px !important;
        margin-top: -8px;
        margin-bottom: 18px;
        max-width: 369px !important;
    }





    .cl-importo {
        max-width: 500px;
        /* padding-left: 11px; */
    }


}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}

#imgPreview {
    display: none;
}


.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 465px !important;
    margin-left: auto;


    display: flex;
    justify-content: center;
    align-items: center;


}


.dropify-wrapper .dropify-message {
    top: 25%;
}

/* #d_importo {
    padding: 16px;
    max-width: 500px;
} */

/* #d_importo {
    max-width: 527px;
    padding-left: 0px;
} */




@media screen and (min-width: 768px) {
    .editSpesa .v-form {
        max-width: 480px !important;
        margin: 0 auto;
        padding-top: 13px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 47px;


    }

    /* #d_importo {
        transform: translateX(10px);
    } */

    /* #d_importo {
        max-width: 500px;
        padding-left: 11px;
    } */

}


@media screen and (max-width: 768px) {
    .dropify-wrapper {
        margin-left: 0px !important;
    }
}

#preview {
    display: none;
}


.active-row {
    background: transparent !important;
    border: 1px solid #00000087 !important;
}


.v-progress-circular__info {
    min-width: 100px;
    transform: translateY(-32px);
    background: white;
}

.v-progress-circular {
    transform: translateY(44px);
}

.span.dropify-filename-inner {
    display: none;
}

.dropify-filename {
    display: none;
}
</style>
